import Image from 'next/image';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { HomepageItemProps } from './constants';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const MainBanner = (props: HomepageItemProps) => {
  const { main_banner } = props;

  return (
    <div className="mb-12">
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mainBanner"
      >
        {main_banner.map((banner, index) => (
          <SwiperSlide key={index} className="relative">
            <div className="md:hidden">
              <Image
                src={banner.mobile_image}
                alt={banner.image_alt}
                quality={100}
                width={0}
                height={0}
                sizes="100vw"
                className=""
              />
            </div>
            <div className="hidden md:block">
              <Image
                src={banner.desktop_image}
                alt={banner.image_alt}
                quality={100}
                width={0}
                height={0}
                sizes="100vw"
                className=""
              />
            </div>
            <div className="absolute w-full bottom-20 md:-translate-y-1/2 md:top-1/2">
              <div className="container">
                <div className=" md:max-w-[500px] text-white">
                  <h1 className="mb-2 text-[32px] leading-[40px] font-bold md:text-[64px] md:leading-[70px] font-dmsans -tracking-[1.6px] md:-tracking-[3.2px]">{banner.text}</h1>
                  <span className="text-base font-normal leading-6 font-dmsans">{banner.description}</span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default MainBanner
