import Image from "next/image";
import Link from "next/link";
import { SectionAccordionItemProps } from "./constants";


const SectionAccordion = (props: SectionAccordionItemProps) => {
  const { title, mobile_title, accordion } = props;

  return (
    <>
      <div className="container hidden m-auto my-20 md:block">
        <h1 className="text-[32px] mb-12 font-dmsans font-bold text-center mx-auto" dangerouslySetInnerHTML={{__html: title}}></h1>
        <div className="flex gap-3 our-values">
          {accordion.map((section, index) => (
            <div className="relative value group" key={index}>
              <Link href={section.link}>
                <Image
                  src={section.desktop_image}
                  alt={section.image_alt}
                  width={1800}
                  height={1238}
                  quality={100}
                  className="w-full"
                />
                <div className="rotate-vertical">
                  <p className="text-5xl font-bold text-center text-white font-dmsans">{section.title}</p>
                </div>
                <div className="absolute z-[5] top-0 left-0 w-full h-full transition duration-300 hover:delay-500 opacity-0 bottom-10 group-hover:hover:opacity-100">
                  <div className="absolute z-10 mx-8 bottom-10">
                    <p className="text-[32px] font-normal text-white value-desc font-dmsans" dangerouslySetInnerHTML={{__html: section.pc_description}}></p>
                    <div className="px-4 py-2 mt-8 text-white border border-white hover:border-secondary border-solid hover:bg-secondary w-fit flex items-center text-[10px]">
                      Discover more
                      <Image
                        src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/arrow-outward.png"}
                        alt="arrow icon"
                        width={12}
                        height={12}
                        quality={100}
                        className="ml-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="absolute top-0 left-0 w-full h-full opacity-40 z-[1] bg-gradient-to-t from-transparent via-black to-black" />
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="container m-auto my-12 md:hidden">
        <h1 className="text-2xl mb-12 font-dmsans font-normal text-left" dangerouslySetInnerHTML={{__html: mobile_title}}></h1>
        <div className="">
          {accordion.map((section, index) => (
            <div className="relative mb-12 value group" key={index}>
              <Link href={section.link}>
                <Image
                  src={section.mobile_image}
                  alt={section.image_alt}
                  width={600}
                  height={848}
                  quality={100}
                  className="w-full h-full"
                />
                <div className="rotate-vertical">
                  <h1 className="text-[32px] text-white font-dmsans font-bold">{section.title}</h1>
                </div>
                <div className="absolute mx-8 text-white bottom-8">
                  <p className="text-xl value-desc font-dmsans" dangerouslySetInnerHTML={{__html: section.mb_description}}></p>
                    <div className="flex items-center px-4 py-2 mt-2 text-white border border-white hover:border-secondary border-solid hover:bg-secondary w-fit text-[10px]">
                      Discover more
                      <Image
                        src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/arrow-outward.png"}
                        alt="arrow icon"
                        width={12}
                        height={12}
                        quality={100}
                        className="ml-2"
                      />
                    </div>
                  </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SectionAccordion
