import Instagram from '../Utils/Instagram';
import MainBanner from './MainBanner';
import OurPickList from './OurPickList';
import { HomepageItemProps } from './constants';
import Work from './Work';
import ShoppingCategory from '../Utils/ShoppingCategory';
import InfoBanner from '../Utils/InfoBanner';
import ShoppingBanner from '../Utils/ShoppingBanner';
import AccordionSection from './AccordionSection';

const Homepage = (props: HomepageItemProps) => {
  const { main_banner, highlight, stage, flash_sale, our_picks, people_say, instagram, shopping_category, info_banner, shopping_banners } = props;

  return (
    <>
      <MainBanner 
        main_banner={main_banner}
        highlight={highlight}
        stage={stage}
        flash_sale={flash_sale}
        our_picks={our_picks}
        people_say={people_say}
        instagram={instagram}
        shopping_category={shopping_category}
        info_banner={info_banner}
        shopping_banners={shopping_banners}
      />
      <OurPickList
        main_banner={main_banner}
        highlight={highlight}
        stage={stage}
        flash_sale={flash_sale}
        our_picks={our_picks}
        people_say={people_say}
        instagram={instagram}
        shopping_category={shopping_category}
        info_banner={info_banner}
        shopping_banners={shopping_banners}
      />
      <Work />
      <ShoppingCategory
        banner_list={shopping_category}
        title=""
      />
      <InfoBanner
        desktop_image={info_banner.desktop_image}
        mobile_image={info_banner.mobile_image}
        image_alt={info_banner.image_alt}
        link={info_banner.link}
        style={info_banner.style}
        text={info_banner.text}
        desc={info_banner.desc}
        button_text={info_banner.button_text}
        custom_text={info_banner.custom_text}
        bg_color='bg-secondaryButtonBg'
        text_color='text-primary'
        button_bg_color='bg-primary'
        button_text_color='text-white'
        mb_button_bg_color='bg-primary'
        mb_button_text_color='text-white'
      />
      <AccordionSection />
      <ShoppingBanner
        banners={shopping_banners}
        brand_image=""
        brand_image_alt=""
        brand_image_style=""
        bg_color=""
        text_color=""
        style=""
        button_bg_color=""
        button_text_color=""
      />
      <Instagram
        instagram={instagram}
        title="Find more about us on Instagram"
        mobile_title="Find more about us on Instagram"
      />
    </>
  )
}

export default Homepage
