import { InferGetStaticPropsType } from "next";
import axios from 'axios';
import Homepage from '@/components/Homepage';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { NextSeo } from "next-seo";


const Home = ({
  homepage,
}: InferGetStaticPropsType<typeof getStaticProps>) => {

  return (
    <>
      <NextSeo
        title=''
        defaultTitle='Green Parenting | Raise a baby in an eco-friendly environment'
        canonical={process.env.NEXT_PUBLIC_HOSTNAME}
        openGraph={{
          images: [{
            url: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo.png",
            width: 577,
            height: 214,
            alt: 'Green Parenting logo image',
          }]
        }}
      />
      <Header />
      <Homepage
        main_banner={homepage.main_banner}
        highlight={homepage.highlight}
        stage={homepage.stage}
        flash_sale={homepage.flash_sale}
        our_picks={homepage.our_picks}
        people_say={homepage.people_say}
        instagram={homepage.instagram}
        shopping_category={homepage.shopping_category}
        info_banner={homepage.info_banner}
        shopping_banners={homepage.shopping_banners}
      />
      <Footer />
    </>
  )
}

export default Home

export const getStaticProps = async () => {
  const response = await axios.get(
    process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_HOMEPAGE!
  );

  return {
    props: { homepage: response.data },
    revalidate: 60,
  }
}
