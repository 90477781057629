import SectionAccordion from "../Utils/SectionAccordion"
import { AccordionItemProps } from "../Utils/constants"


const accordion_list: Array<AccordionItemProps> = [
  {
    title: "MOOIMOM",
    pc_description: "The Ultimate Brand to<br />Accompany a Mom's Journey",
    mb_description: "The Ultimate Brand to<br />Accompany a Mom's<br />Journey",
    link: "/brand/mooimom",
    desktop_image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/mooimom-pc.jpg",
    mobile_image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/mooimom-mb.jpg",
    image_alt: "MOOIMOM banner"
  },
  {
    title: "mugu",
    pc_description: "Made with Heart.<br />Mate for Every Kids.",
    mb_description: "Made with Heart.<br />Mate for Every Kids.",
    link: "/brand/mugu",
    desktop_image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/mugu-pc.jpg",
    mobile_image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/mugu-mb.jpg",
    image_alt: "mugu banner"
  },
  // {
  //   title: "Nero Bianco",
  //   pc_description: "All<br />About Simplicity.",
  //   mb_description: "All<br />About Simplicity.",
  //   link: "/brand/nero-bianco",
  //   desktop_image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/nero-bianco-pc.jpg",
  //   mobile_image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/nero-bianco-mb.jpg",
  //   image_alt: "Nero Bianco banner"
  // },
  // {
  //   title: "Tweeling",
  //   pc_description: "Cherish The Bonding<br />Moment With Your<br />Little One",
  //   mb_description: "Cherish The Bonding<br />Moment With Your<br />Little One",
  //   link: "/brand/tweeling",
  //   desktop_image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/tweeling-pc.jpg",
  //   mobile_image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/brand/tweeling-mb.jpg",
  //   image_alt: "Tweeling banner"
  // }
]

const AccordionSection = () => {

  return (
    <SectionAccordion
      title="From gentle baby care to sustainable maternity goods,<br />explore products that embrace your values."
      mobile_title="From gentle baby care to sustainable maternity goods, explore products that embrace your values."
      accordion={accordion_list}
    />
  )
}

export default AccordionSection
