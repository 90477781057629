import Image from 'next/image';


const work_list = [
  {
    "image": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/earth-icon.png",
    "image_alt": "earth icon",
    "text": "A Healthier Future"
  },
  {
    "image": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/leaf-icon.png",
    "image_alt": "leaf icon",
    "text": "Consume Wisely"
  },
  {
    "image": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/tree-icon.png",
    "image_alt": "tree icon",
    "text": "Environmental Change"
  }
]

const Work = () => {
  return (
    <div className="container my-20 text-center md:text-left md:flex md:items-center md:justify-between">
      <h3 className="text-3xl md:text-6xl text-primary font-dmsans font-medium leading-normal mb-8 md:mb-0">How does<br />Green Parenting<br />Work?</h3>
      <div className="hidden md:block max-w-[640px]">
        {work_list.map((work, index) => (
          <div key={index}>
            <div className="flex items-center" key={index}>
              <Image
                src={work.image}
                alt={work.image_alt}
                quality={100}
                width={65}
                height={65}
              />
              <p className="ml-[57px] text-[32px] text-primary font-dmsans font-medium">{work.text}</p>
            </div>
            {index < work_list.length - 1 && (
              <hr className="bg-border my-8" />
            )}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-3 gap-2 md:hidden">
        {work_list.map((work, index) => (
          <div className="grid grid-cols-1 justify-items-center" key={index}>
            <Image
              src={work.image}
              alt={work.image_alt}
              quality={100}
              width={65}
              height={65}
            />
            <p className="text-sm text-primaryText font-dmsans font-medium mt-4">{work.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Work
