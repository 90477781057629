import OurPick from "../Utils/OurPick";
import { HomepageItemProps } from "./constants"


const OurPickList = (props: HomepageItemProps) => {
  const { our_picks } = props;

  return (
    <div className="container">
      {our_picks.map((pick, index) => (
        <OurPick
          key={index}
          tittle={pick.tittle}
          see_all_link={pick.see_all_link}
          banner_list={pick.banner_list}
          product_list={pick.product_list}
          attrId={null}
          customName={null}
          expandColor={false}
          showBorder={true}
        />
      ))}
    </div>
  )
}

export default OurPickList
